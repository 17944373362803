@import "./less.less";
@import "../defaults/css.less";

:global {
	:root {
		.light {
			// Background
			--background-primary: #fff;
			--background-secondary: #F6F6F6;
			--background-tertiary: #fff;

			/* HEADER */
			--header-background: #E6007E;
			--header-pull-background: #E6007E;
			--header-border-color: #930051;
			--header-icon-color: #fff;

			// Font
			--font-primary: #272727;
			--font-secondary: #fff;
			--font-disabled: rgba(#272727, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #272727;


			// Icon
			--icon-default: #272727;
			--icon-active: #272727;
			--icon-disabled: rgba(#272727, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #272727;
			--icon-neutral-grey1: #f6f6f6;
			--icon-neutral-inverse: #fff;

			// Neutrals
			--white1: #fff;
			--black1: #272727;
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#282828, 0.1);
			--neutral3-background-fill: #F0F0F0;
			--neutral4-grey: #999999;
			--neutral5-grey-fill: #DCDCDC;
			--neutral6-neutral-fill: #272727;
			--neutral7-neutral-fill2: #fff;

			// Menu
			--menu-default: #272727;
			--menu-active: #E6007E;
			--menu-disabled: rgba(#272727, 0.3);

			// Colors
			--primary1: #E6007E;
			--primary2: #930051;
			--accent1: #02ADD9;
			--accent2: #2779A1;
			--accent3: #FFDB4D;
			--accent4: #009977;
			--alert-info: #3333FF;
			--alert-warning: #FFDB4D;
			--alert-warning2: #E30613;
			--gridlines: #FF3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);
		}

		.dark {
			// Background
			--background-primary: #1C1C1C;
			--background-secondary: #282828;
			--background-tertiary: #1C1C1C;

			/* HEADER */
			--header-background: #E6007E;
			--header-pull-background: #E6007E;
			--header-border-color: #930051;
			--header-icon-color: #fff;

			// Font
			--font-primary: #fff;
			--font-secondary: #272727;
			--font-disabled: rgba(#fff, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #272727;

			// Icon
			--icon-default: #fff;
			--icon-active: #272727;
			--icon-disabled: rgba(#fff, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #272727;
			--icon-neutral-grey1: #f6f6f6;
			--icon-neutral-inverse: #272727;

			// Neutrals
			--white1: #fff;
			--black1: #272727;
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#fff, 0.1);
			--neutral3-background-fill: #F0F0F0;
			--neutral4-grey: #999999;
			--neutral5-grey-fill: #DCDCDC;
			--neutral6-neutral-fill: #fff;
			--neutral7-neutral-fill2: #282828;

			// Menu
			--menu-default: #fff;
			--menu-active: #E6007E;
			--menu-disabled: rgba(#fff, 0.3);

			// Colors
			--primary1: #E6007E;
			--primary2: #930051;
			--accent1: #02ADD9;
			--accent2: #2779A1;
			--accent3: #FFDB4D;
			--accent4: #009977;
			--alert-info: #3333FF;
			--alert-warning: #FFDB4D;
			--alert-warning2: #E30613;
			--gridlines: #FF3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);
		}
	}
}
